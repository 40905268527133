import React from "react"
import { Text } from "deskie-ui"
import Container from "../components/Container"
import PageWrapper from "../components/PageWrapper"
import PlanMeeting from "../components/PlanMeeting"
import styled from "styled-components"
import { Helmet } from "react-helmet"

const Header = styled(Container)`
  margin-top: -6rem;
  padding-top: 8rem;
  padding-bottom: 6rem;

  h1 {
    color: white;
    font-weight: 600;
    font-size: min(
      max(1.75rem, calc(1.75rem + ((1vw - 0.4rem) * 1.875))),
      2.5rem
    );
    line-height: 1.3;
    margin: 0 0 0.5rem;
  }

  p {
    color: rgba(255, 255, 255, 0.7);
  }

  background-image: url("/paths.svg");
  background-size: auto 160%;
  background-position: 100% 0%;
  background-repeat: no-repeat;
`

const Content = styled(Container)`
  padding-bottom: 3rem;

  h2 {
    color: ${props => props.theme.colors.primary};
  }

  .button {
    margin-top: 2rem;
  }
`

function Contact() {
  const title = "Neem contact op met Deskie Support | 038 760 1060"
  const desc = "Heb je een vraag over Deskie? We staan klaar om je te helpen!"
  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={desc} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={desc} />
        <meta name="keywords" content="Contact, Deskie" />
      </Helmet>
      <Header>
        <Text h1>Deskie Support </Text>
      </Header>
      <PageWrapper>
        <Content>
          <Text p>
            Heb je een vraag over Deskie? We staan klaar om je te helpen! Mail
            ons op{" "}
            <a
              href="mailto:info@deskie.nl"
              target="_blank"
              rel="noopener noreferrer"
            >
              info@deskie.nl
            </a>{" "}
            of bel naar{" "}
            <a href="tel:0387601066" target="_blank" rel="noopener noreferrer">
              038 760 1066.
            </a>
          </Text>
        </Content>
        <PlanMeeting />
      </PageWrapper>
    </>
  )
}

export default Contact
